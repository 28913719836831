const productTypes = [
  "pounds",
  "bags",
  "each",
  "kilograms",
  "gallons",
  "quarts",
  "pints",
  "cups",
];

export default productTypes;
